import { Invoice } from 'components/ExportPDFModal/types';
import { Dayjs } from 'dayjs';

export interface Version {
    idVersao: number;
    versao: string;
    sistemaOperacional: string;
    status: number;
    dataLancamento: string;
    obrigatorio: string;
    dataInclusao: string;
    usuario: string;
    dataHoraAlteracao: string | null;
    indicaExclusao: string | null;
}

export interface VersioningProps {
    quantidadePaginas: number;
    quantidade: number;
    versions: Version[];
}

export interface itemForm {
    value: string;
    error: boolean;
    textError?: string;
}

export interface itemFormDate {
    value: Dayjs | null;
    error: boolean;
    textError?: string;
}

export interface FormFields {
    itensPorPagina: string;
    emitente: itemForm;
    cliente: itemForm;
    numeroNF: itemForm[];
    dataEmissaoNFe: {
        periodoDe: itemFormDate;
        periodoAte: itemFormDate;
    };
    dataBaixaCanhoto: {
        periodoDe: itemFormDate;
        periodoAte: itemFormDate;
    };
    modalidadeFrete: {
        cif: boolean;
        fob: boolean;
    };
    statusCanhoto: itemForm;
    chaveNFe: itemForm[];
    serie: itemForm;
    produto: itemForm;
    volume: {
        rangeDe: itemForm;
        rangeAte: itemForm;
    };
    numeroPedido: itemForm;
    nomeTransportador: itemForm;
    CNPJTransportador: itemForm;
    codigoTransportador: itemForm;
}

export interface InvoiceFiltredModal {
    invoices: Invoice[];
    show: boolean;
    type: string;
}

export const initialState: FormFields = {
    itensPorPagina: '10',
    emitente: { value: '', error: false },
    cliente: { value: '', error: false },
    numeroNF: [{ value: '', error: false }],
    dataEmissaoNFe: {
        periodoDe: { value: null, error: false },
        periodoAte: { value: null, error: false },
    },
    dataBaixaCanhoto: {
        periodoDe: { value: null, error: false },
        periodoAte: { value: null, error: false },
    },
    modalidadeFrete: { cif: false, fob: false },
    statusCanhoto: { value: 'all', error: false },
    chaveNFe: [{ value: '', error: false }],
    serie: { value: '', error: false },
    produto: { value: '', error: false },
    volume: {
        rangeDe: { value: '', error: false },
        rangeAte: { value: '', error: false },
    },
    numeroPedido: { value: '', error: false },
    nomeTransportador: { value: '', error: false },
    CNPJTransportador: { value: '', error: false },
    codigoTransportador: { value: '', error: false },
};

export const STATUS_CANHOTO = [
    {
        value: 'all',
        label: 'Todos',
    },
    {
        value: '0',
        label: 'Em trânsito',
    },
    {
        value: '1',
        label: 'Assinado',
    },
    {
        value: '2',
        label: 'Baixado',
    },
    {
        value: '3',
        label: 'Em trânsito a +30 dias',
    },
    {
        value: '4',
        label: 'Baixado manualmente',
    },
    {
        value: '5',
        label: 'Retornado',
    },
];

export const columnsTitles = [
    'Emitente',
    'Cliente',
    'Data de emissão',
    'Data de baixa',
    'N° NF-e',
    'Status',
    'Modalidade do Frete',
];
